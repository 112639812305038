import { GetServerSideProps } from 'next';
import Head from 'next/head';

import RenderBlocks from 'components/cms-blocks/render-blocks';
import { TSanityBlock } from 'components/cms-blocks/render-blocks/types';
import Main from 'components/common/main';
import getMetaTags from 'components/common/meta-tags';
import { ICustomMetaTagsSanity } from 'components/common/meta-tags/types';
import StructuredData from 'components/common/structured-data';
import { filterDataToSingleItem } from 'framework/sanity/filter-data-to-single-item';
import { mapBlocks } from 'framework/sanity/map-blocks';
import { homePageQuery } from 'framework/sanity/queries';
import { articlesLandingQuery } from 'framework/sanity/queries/articles-landing';
import { getClient } from 'framework/sanity/sanity-client';
import { setCacheControlTenMinutes } from 'utils/cache';

interface HomeProps {
  data: {
    page: {
      blocks: TSanityBlock[];
      meta?: ICustomMetaTagsSanity;
    };
  };
}

export const SPROUTL_ORGANIZATION_STRUCTURED_DATA = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Sproutl',
  description:
    "Hello. We're Sproutl. We're on a mission to make gardening easy and open to all. Plants, essentials and more, from the UK's best garden centres and independent shops, delivered to your door.",
  url: 'https://www.sproutl.com',
  email: 'helpme@sproutl.com',
  logo: 'https://www.sproutl.com/assets/images/logo-green.svg',
  sameAs: [
    'https://www.facebook.com/we.are.sproutl',
    'https://twitter.com/wearesproutl',
    'https://www.instagram.com/wearesproutl',
    'https://www.youtube.com/channel/UCGgGNbp7wZ5Iuv5BKJG6jKg',
    'https://www.linkedin.com/company/sproutl',
    'https://github.com/sproutl',
    'https://www.pinterest.co.uk/wearesproutl/',
    'https://uk.trustpilot.com/review/sproutl.com',
  ],
};

export const SPROUTL_WEBSITE_STRUCTURED_DATA = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'Sproutl',
  url: 'https://www.sproutl.com',
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: 'https://www.sproutl.com/search?q={search_term_string}',
    },
    'query-input': 'required name=search_term_string',
  },
};

export default function Home({ data: { page } }: HomeProps) {
  return (
    <>
      <Head>{getMetaTags({ meta: page.meta })}</Head>
      <StructuredData data={SPROUTL_ORGANIZATION_STRUCTURED_DATA} />
      <StructuredData data={SPROUTL_WEBSITE_STRUCTURED_DATA} />
      <Main>
        <RenderBlocks blocks={page.blocks || []} />
      </Main>
    </>
  );
}

interface IHomepageQuery {
  _id: string;
  blocks: TSanityBlock[];
}

interface IArticlesLandingQuery {
  _id: string;
  blocks: TSanityBlock[];
}

export const getServerSideProps: GetServerSideProps = async ({
  preview = false,
  res,
}) => {
  setCacheControlTenMinutes(res);

  const data = await getClient().fetch<IArticlesLandingQuery[]>(
    articlesLandingQuery,
  );

  const page = filterDataToSingleItem(data);

  if (!page) {
    const fallbackData = await getClient().fetch<IHomepageQuery[]>(
      homePageQuery,
    );
    const fallbackPage = filterDataToSingleItem(fallbackData);
    const fallbackBlocks = await mapBlocks(fallbackPage.blocks);

    return {
      props: {
        preview,
        data: {
          page: {
            ...fallbackPage,
            blocks: fallbackBlocks,
          },
        },
      },
    };
  }

  const blocks = await mapBlocks(page.blocks);

  return {
    props: {
      preview,
      data: {
        page: {
          ...page,
          blocks,
        },
      },
    },
  };

  // const data = await getClient().fetch<IHomepageQuery[]>(homePageQuery);
  // const page = filterDataToSingleItem(data);
  // const blocks = await mapBlocks(page.blocks);

  // return {
  //   props: {
  //     preview,
  //     data: {
  //       page: {
  //         ...page,
  //         blocks,
  //       },
  //     },
  //   },
  // };
};
